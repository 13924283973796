import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import {
  ALL_EC2_INSTANCE_TYPES,
  FILTERED_EC2_INSTANCE_TYPES,
} from '../../../../../../app/data/ec2-aws-details';
import {
  CreateEc2State,
  EC2Form,
  Ec2FormField,
  Ec2TemplatePayload,
  TemplateTab,
} from './createEc2Interfaces';
import {
  addAwsPrices,
  EC2_INSTANCE_CLASSES,
  filterInstancesWithGreaterThanTwoRam,
  generateInitialEc2Form,
  mergeTemplateStateHelper,
} from './createEc2Helpers';
import { RootState, store } from '../../../../../../app/store';
import { ec2PostThunk } from './createEc2Thunks';
import extendedPlaybooksApi from '../../../../../../services/playbooks/extendedPlaybooksApi';
import {
  AssociatedS3Data,
  AssociatedSecrets,
  CortexComponent,
} from '../../../../../../models/compute/Ec2';
import {
  CortexPlaybook,
  CortexPlaybookRecords,
} from '../../../../../../models/compute/Playbooks';
import { Profile } from '../../../../../../models/cortex/Accounts';
import { ec2PostTemplateThunk } from './createEc2TemplateThunk';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../../../../utils';
import { generateInstanceName } from '../../../../../../app/utilities/generateInstanceName';
import { CoreMarketType } from '../../emr/slices/createEmrInterfaces';

const DEFAULT_PLAYBOOKS = ['zscaler'];

export const allEc2InstanceTypes = ALL_EC2_INSTANCE_TYPES.map((i) =>
  addAwsPrices(i, 'EC2')
);

export const ec2InstanceTypes = FILTERED_EC2_INSTANCE_TYPES.filter(
  filterInstancesWithGreaterThanTwoRam
).map((i) => addAwsPrices(i, 'EC2'));

const IS_DATA_LAB = process.env.REACT_APP_LDL_USER === 'true';

export const initialEc2Form: EC2Form = generateInitialEc2Form(
  store?.getState()?.cortexAccount?.region || 'US_EAST_1',
  IS_DATA_LAB
);

const initialState: CreateEc2State = {
  instances: ec2InstanceTypes,
  startInstanceOnCreation: true,
  playbookRecords: {},
  selectedInstanceClass: EC2_INSTANCE_CLASSES.COMPUTE_OPTIMIZED,
  selectedRecommendedToggle: 'recommendations',
  selectedCores: 1,
  selectedMemory: 2,
  selectedStorage: 0,
  pageSize: 10,
  selectedMarketType: 'ON_DEMAND',
  ec2Form: initialEc2Form,
  postData: {
    loading: false,
    error: null,
  },
  configuration: {
    templateTab: IS_DATA_LAB
      ? 'advanced'
      : (getLocalStorageItem('ec2TemplateTab', 'templates') as TemplateTab),
    grmDataAccessMethod: 'BRANDENBURG',
  },
};

export const createEc2Slice = createSlice({
  name: 'createEc2',
  initialState,
  reducers: {
    resetInstanceName(state) {
      state.ec2Form.name = generateInstanceName();
    },
    resetCortexComponents(state) {
      state.ec2Form.cortexComponents = initialEc2Form.cortexComponents;
    },
    resetInstanceState(state) {
      state.ec2Form = initialEc2Form;
    },
    setSelectedInstanceClass: (state, action: PayloadAction<string>) => {
      state.selectedInstanceClass = action.payload;
      state.selectedMemory = 2;
      state.selectedStorage = 0;
      state.selectedCores = 1;
    },
    setSelectedRecommendedToggle: (state, action: PayloadAction<string>) => {
      state.selectedRecommendedToggle = action.payload;
    },
    setSelectedCores: (state, action: PayloadAction<number>) => {
      state.selectedCores = action.payload;
    },
    setSelectedMemory: (state, action: PayloadAction<number>) => {
      state.selectedMemory = action.payload;
    },
    setSelectedStorage: (state, action: PayloadAction<number>) => {
      state.selectedStorage = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSelectedMarketType: (state, action: PayloadAction<CoreMarketType>) => {
      state.selectedMarketType = action.payload;
    },
    setStartInstanceOnCreation: (state, action: PayloadAction<boolean>) => {
      state.startInstanceOnCreation = action.payload;
    },
    setTemplateField: (state, action: PayloadAction<Ec2FormField>) => {
      const templateField = action.payload.field;
      const templateValue = action.payload.value;
      state.ec2Form = {
        ...state.ec2Form,
        [templateField]: templateValue,
      };
    },
    setCortexComponents: (state, action: PayloadAction<CortexComponent[]>) => {
      state.ec2Form.cortexComponents = action.payload;
    },
    setCortexPlaybooks: (state, action: PayloadAction<CortexPlaybook[]>) => {
      state.ec2Form.cortexPlaybooks = action.payload;
    },
    setCortexPlaybookRecords: (
      state,
      action: PayloadAction<CortexPlaybookRecords>
    ) => {
      state.playbookRecords = action.payload;
    },
    setCortexPlaybookRecordArgs: (
      state,
      action: PayloadAction<{ playbookName: string; args: string }>
    ) => {
      const { playbookName, args } = action.payload;
      state.playbookRecords[playbookName].args = args;
    },
    selectCortexPlaybookRecord: (
      state,
      action: PayloadAction<{ playbookName: string; selected: boolean }>
    ) => {
      const { playbookName, selected } = action.payload;
      state.playbookRecords[playbookName].selected = selected;
    },
    setAssociatedS3Buckets: (
      state,
      action: PayloadAction<AssociatedS3Data[]>
    ) => {
      state.ec2Form.associatedBuckets = action.payload;
    },
    setDaeSelection: (state, action: PayloadAction<string[]>) => {
      state.ec2Form.associatedData = action.payload;
    },
    setAssociatedData: (state, action: PayloadAction<Profile>) => {
      if (action.payload.type === 'CORTEX') {
        state.ec2Form.associatedData = undefined;
        state.ec2Form.userProfile = action.payload.projectName;
      } else if (action.payload.type === 'BRANDENBURG') {
        state.ec2Form.userProfile = undefined;
        state.ec2Form.associatedData = [
          `${action.payload.type}/${action.payload.projectName}/${action.payload.projectId}`,
        ];
      } else {
        state.ec2Form.associatedData = undefined;
        state.ec2Form.userProfile = undefined;
      }
    },
    setAssociatedSecrets: (
      state,
      action: PayloadAction<AssociatedSecrets[]>
    ) => {
      state.ec2Form.secrets = action.payload;
    },
    mergeTemplateState: (state, action: PayloadAction<Ec2TemplatePayload>) => {
      const template = action.payload.template;
      const availableBuckets = action.payload.availableBuckets;
      mergeTemplateStateHelper(state, template);
      // handle playbooks
      template.cortexPlaybooks.forEach((cortexPlaybook) => {
        state.playbookRecords[cortexPlaybook.playbookName].selected = true;
        state.playbookRecords[cortexPlaybook.playbookName].args =
          cortexPlaybook.args;
      });
      // handle associated s3 buckets
      if (template.associatedBuckets) {
        const associatedS3Buckets: AssociatedS3Data[] =
          template.associatedBuckets.filter((associatedBucket) =>
            availableBuckets.find(
              (availableBucket) =>
                availableBucket.bucket.bucketName ===
                associatedBucket.bucketName
            )
          );
        state.ec2Form.associatedBuckets = associatedS3Buckets;
      }
    },
    setTemplateTab: (state, action: PayloadAction<TemplateTab>) => {
      const templateTab = action.payload;
      state.configuration.templateTab = templateTab;
      setLocalStorageItem('ec2TemplateTab', templateTab);
    },
    setGrmDataAccessMethod: (
      state,
      action: PayloadAction<'BRANDENBURG' | 'DAE'>
    ) => {
      const newDataAccessMethod = action.payload;
      state.ec2Form.associatedData = [];
      state.ec2Form.userProfile = undefined;
      state.configuration.grmDataAccessMethod = newDataAccessMethod;
    },
  },
  extraReducers: (builder) => {
    //GET QUERY LIST
    builder.addCase(ec2PostThunk.pending, (state) => {
      state.postData.loading = true;
      state.postData.error = null;
    });
    builder.addCase(ec2PostThunk.fulfilled, (state) => {
      state.postData.loading = false;
      state.postData.error = null;
    });
    builder.addCase(ec2PostThunk.rejected, (state, { payload }) => {
      state.postData.loading = false;
      state.postData.error = payload;
    });
    builder.addCase(ec2PostTemplateThunk.pending, (state) => {
      state.postData.loading = true;
      state.postData.error = null;
    });
    builder.addCase(ec2PostTemplateThunk.fulfilled, (state) => {
      state.postData.loading = false;
      state.postData.error = null;
    });
    builder.addCase(ec2PostTemplateThunk.rejected, (state, { payload }) => {
      state.postData.loading = false;
      state.postData.error = payload;
    });
    builder.addMatcher(
      extendedPlaybooksApi.endpoints.getPlaybooksList.matchFulfilled,
      (state, { payload }) => {
        payload.forEach(
          (playbook) =>
            (state.playbookRecords[playbook.name] = {
              playbookName: playbook.name,
              args: '',
              selected: DEFAULT_PLAYBOOKS.includes(playbook.name),
              installedAtLogin: playbook.installedAtLogin,
              routable: playbook.args.includes('server_name'),
            })
        );
      }
    );
  },
});

export const {
  mergeTemplateState,
  resetCortexComponents,
  resetInstanceName,
  resetInstanceState,
  selectCortexPlaybookRecord,
  setAssociatedData,
  setAssociatedS3Buckets,
  setAssociatedSecrets,
  setCortexComponents,
  setCortexPlaybookRecordArgs,
  setCortexPlaybookRecords,
  setCortexPlaybooks,
  setDaeSelection,
  setGrmDataAccessMethod,
  setSelectedInstanceClass,
  setSelectedRecommendedToggle,
  setSelectedCores,
  setSelectedMemory,
  setSelectedStorage,
  setPageSize,
  setSelectedMarketType,
  setTemplateField,
  setTemplateTab,
  setStartInstanceOnCreation,
} = createEc2Slice.actions;

export const getEc2CreateConfiguration = (state: RootState) =>
  state.createEc2.configuration;

export default createEc2Slice.reducer as Reducer<typeof initialState>;
